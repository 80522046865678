// export const tableData = [
//   {
//     transactionDetails: { transactionNo: '000/23/215/5', count: 3, supplierName: 'Beardo pvt LTD' },
//     items: '30',
//     transType: 'Purchase',
//     toBill: '1',
//     transCount: '12',
//     totalAmount: '12345',
//     offInvoice: '2%',
//     days: '12',
//     amount: '12345',
//     status: 'Overdue',
//     action: '',
//     report: '',
//     modalData: [
//       { supplierName: 'Beardo pvt LTD', totalAmount: '12345', gst: '18%', tds: '10%', refNo: 'REF123', action: true },
//       { supplierName: 'Beardo pvt LTD', totalAmount: '12345', gst: '18%', tds: '10%', refNo: 'REF323', action: true },
//     ],
//   },
//   {
//     transactionDetails: { transactionNo: '000/23/215/5', count: 3, supplierName: 'Beardo pvt LTD' },
//     items: '30',
//     transType: 'Purchase',
//     toBill: '1',
//     transCount: '12',
//     totalAmount: '12345',
//     offInvoice: '2%',
//     days: '12',
//     amount: '12345',
//     status: 'Paid',
//     action: '',
//     report: '',
//     modalData: [
//       { supplierName: 'Beardo pvt LTD', totalAmount: '12345', gst: '18%', tds: '10%', refNo: 'REF124', action: false },
//     ],
//   },
//   {
//     transactionDetails: { transactionNo: '000/23/215/5', count: 3, supplierName: 'Beardo pvt LTD' },
//     items: '30',
//     transType: 'Purchase',
//     toBill: '1',
//     transCount: '12',
//     totalAmount: '12345',
//     offInvoice: '2%',
//     days: '12',
//     amount: '12345',
//     status: 'Unpaid',
//     action: '',
//     report: '',
//     modalData: [
//       { supplierName: 'Beardo pvt LTD', totalAmount: '12345', gst: '18%', tds: '10%', refNo: 'REF125', action: true },
//     ],
//   },
// ];

// export const tableData = {
//   'c_act_code': 'S11839',
//   'supp_code': 'S11839',
//   'transaction_details': { 'tot_no': 13, 'mfac_name': 'safds', 'off_invoice_per': 12 },
//   'trans_supp_code_count': 8401,
//   'trans_item_code_count': 8401,
//   'trans_bill_count': 8401,
//   'trans_tax_type': 'MRP with tax',
//   'trans_type': 'Sales',
//   'd_date': '2024-08-22',
//   'currentTime': '2024-08-22 16:46:14.954814',
//   'Remark': 'Claim Amount',
//   'details': [{
//     'entity_id': 'S11839',
//     'c_supp_name': 'ZED LIFESTYLE PVT LTD',
//     'customer_reg_number': '24AAACZ9479M1ZH',
//     'n_gst_applicable': 1,
//     'mfac_c_gst_code': '18',
//     'store_code': '000',
//     'order_type': 'NMR NEFT',
//     'type': 'CREDIT',
//     'round_off': 'NO',
//     'payment_type': 'CREDIT',
//     'date': '22/08/2024',
//     'due_date': '22/08/2024',
//     'bill_date': '22/08/2024',
//     'customform': '129',
//     'tax_type': 'GST',
//     'transaction_type': 'billcredit',
//     'account_segment': '1',
//     'sku': 'NMR Off-Invoice - PHARMA',
//     'transactionsource': 'EG_POS',
//     'department': 'Merchandise - Pharma',
//     'indian_tax_section_code': '294',
//     'description': '7% commission on xyz amount',
//     'total_amount': 376299.31,
//     'total_tax': 67733.8758,
//     'total_CGST': 0.0,
//     'total_SGST': 0.0,
//     'total_IGST': 67733.8758,
//     'total_cess': 0
//   }]
// }

// export const tableData = {
//   "c_act_code": "(NULL)",
//   "total_claim_amt": 8953.83,
//   "supp_code": "S20210",
//   "tot_no": 53,
//   "mfac_name": "DROOLS PET FOOD PVT LTD",
//   "trans_supp_code_count": 274,
//   "trans_item_code_count": 274,
//   "off_invoice_per": 3,
//   "trans_bill_count": 274,
//   "trans_tax_type": "MRP with tax",
//   "trans_type": "Purchase",
//   "d_date": "2024-09-03",
//   "currentTime": "2024-09-03 17:55:45.761540",
//   "Remark": "Claim Amount",
//   "tot_report": "https://wellnessforever01.s3.ap-south-1.amazonaws.com/NMR_TOT_FILE/NMR-53-2024-09-02.zip",
//   "details": [
//     {
//       "supp_code": "S04818",
//       "c_supp_name": "SHREE SAI ENTERPRISES (BADLAPUR)",
//       "customer_reg_number": "27BFOPB6448F1ZB",
//       "n_gst_applicable": 1,
//       "mfac_c_gst_code": "18",
//       "store_code": "000",
//       "order_type": "NMR NEFT",
//       "type": "CREDIT",
//       "round_off": "NO",
//       "payment_type": "CREDIT",
//       "date": "03/09/2024",
//       "due_date": "03/09/2024",
//       "bill_date": "03/09/2024",
//       "customform": "127",
//       "tax_type": "GST",
//       "transaction_type": "vendorcredit",
//       "account_segment": "1",
//       "sku": "NMR Off-Invoice -PHARMA",
//       "transactionsource": "EG_POS",
//       "department": 30,
//       "indian_tax_section_code": "294",
//       "description": "7% commission on xyz amount",
//       "total_amount": 110.46,
//       "total_tax": 19.8828,
//       "total_CGST": 9.9414,
//       "total_SGST": 9.9414,
//       "total_IGST": 0.0,
//       "total_cess": 0
//     },
//     {
//       "supp_code": "S15155",
//       "c_supp_name": "YASH DISTRIBUTOR(GOA)",
//       "customer_reg_number": "30ALRPM9170H1ZT",
//       "n_gst_applicable": 1,
//       "mfac_c_gst_code": "18",
//       "store_code": "000",
//       "order_type": "NMR NEFT",
//       "type": "CREDIT",
//       "round_off": "NO",
//       "payment_type": "CREDIT",
//       "date": "03/09/2024",
//       "due_date": "03/09/2024",
//       "bill_date": "03/09/2024",
//       "customform": "127",
//       "tax_type": "GST",
//       "transaction_type": "vendorcredit",
//       "account_segment": "1",
//       "sku": "NMR Off-Invoice -PHARMA",
//       "transactionsource": "EG_POS",
//       "department": 30,
//       "indian_tax_section_code": "294",
//       "description": "7% commission on xyz amount",
//       "total_amount": 215.49,
//       "total_tax": 38.7882,
//       "total_CGST": 0.0,
//       "total_SGST": 0.0,
//       "total_IGST": 38.7882,
//       "total_cess": 0
//     },
//     {
//       "supp_code": "S16041",
//       "c_supp_name": "UNIQUE DISTRIBUTORS(SANGLI)",
//       "customer_reg_number": "27CQQPP7287Q1ZD",
//       "n_gst_applicable": 1,
//       "mfac_c_gst_code": "18",
//       "store_code": "000",
//       "order_type": "NMR NEFT",
//       "type": "CREDIT",
//       "round_off": "NO",
//       "payment_type": "CREDIT",
//       "date": "03/09/2024",
//       "due_date": "03/09/2024",
//       "bill_date": "03/09/2024",
//       "customform": "127",
//       "tax_type": "GST",
//       "transaction_type": "vendorcredit",
//       "account_segment": "1",
//       "sku": "NMR Off-Invoice -PHARMA",
//       "transactionsource": "EG_POS",
//       "department": 30,
//       "indian_tax_section_code": "294",
//       "description": "7% commission on xyz amount",
//       "total_amount": 169.38,
//       "total_tax": 30.488400000000002,
//       "total_CGST": 15.244200000000001,
//       "total_SGST": 15.244200000000001,
//       "total_IGST": 0.0,
//       "total_cess": 0
//     },
//     {
//       "supp_code": "S17965",
//       "c_supp_name": "SARVODAY AGENCIES",
//       "customer_reg_number": "29AAKPH5849L1Z0",
//       "n_gst_applicable": 1,
//       "mfac_c_gst_code": "18",
//       "store_code": "000",
//       "order_type": "NMR NEFT",
//       "type": "CREDIT",
//       "round_off": "NO",
//       "payment_type": "CREDIT",
//       "date": "03/09/2024",
//       "due_date": "03/09/2024",
//       "bill_date": "03/09/2024",
//       "customform": "127",
//       "tax_type": "GST",
//       "transaction_type": "vendorcredit",
//       "account_segment": "1",
//       "sku": "NMR Off-Invoice -PHARMA",
//       "transactionsource": "EG_POS",
//       "department": 30,
//       "indian_tax_section_code": "294",
//       "description": "7% commission on xyz amount",
//       "total_amount": 198.48000000000002,
//       "total_tax": 35.726400000000005,
//       "total_CGST": 0.0,
//       "total_SGST": 0.0,
//       "total_IGST": 35.726400000000005,
//       "total_cess": 0
//     },
//     {
//       "supp_code": "S20210",
//       "c_supp_name": "DROOLS PET FOOD PRIVATE LIMITED",
//       "customer_reg_number": "27AAGCD8374B1ZM",
//       "n_gst_applicable": 1,
//       "mfac_c_gst_code": "18",
//       "store_code": "000",
//       "order_type": "NMR NEFT",
//       "type": "CREDIT",
//       "round_off": "NO",
//       "payment_type": "CREDIT",
//       "date": "03/09/2024",
//       "due_date": "03/09/2024",
//       "bill_date": "03/09/2024",
//       "customform": "127",
//       "tax_type": "GST",
//       "transaction_type": "vendorcredit",
//       "account_segment": "1",
//       "sku": "NMR Off-Invoice -PHARMA",
//       "transactionsource": "EG_POS",
//       "department": 30,
//       "indian_tax_section_code": "294",
//       "description": "7% commission on xyz amount",
//       "total_amount": 1589.94,
//       "total_tax": 286.1892,
//       "total_CGST": 143.0946,
//       "total_SGST": 143.0946,
//       "total_IGST": 0.0,
//       "total_cess": 0
//     },
//     {
//       "supp_code": "S20779",
//       "c_supp_name": "DROOLS PET FOOD PVT LTD (BANGALORE)",
//       "customer_reg_number": "29AAGCD8374B1ZI",
//       "n_gst_applicable": 1,
//       "mfac_c_gst_code": "18",
//       "store_code": "000",
//       "order_type": "NMR NEFT",
//       "type": "CREDIT",
//       "round_off": "NO",
//       "payment_type": "CREDIT",
//       "date": "03/09/2024",
//       "due_date": "03/09/2024",
//       "bill_date": "03/09/2024",
//       "customform": "127",
//       "tax_type": "GST",
//       "transaction_type": "vendorcredit",
//       "account_segment": "1",
//       "sku": "NMR Off-Invoice -PHARMA",
//       "transactionsource": "EG_POS",
//       "department": 30,
//       "indian_tax_section_code": "294",
//       "description": "7% commission on xyz amount",
//       "total_amount": 70.53,
//       "total_tax": 12.6954,
//       "total_CGST": 0.0,
//       "total_SGST": 0.0,
//       "total_IGST": 12.6954,
//       "total_cess": 0
//     },
//     {
//       "supp_code": "S20789",
//       "c_supp_name": "DROOLS PET FOOD PVT LTD (GOA)",
//       "customer_reg_number": "30AAGCD8374B1ZZ",
//       "n_gst_applicable": 1,
//       "mfac_c_gst_code": "18",
//       "store_code": "000",
//       "order_type": "NMR NEFT",
//       "type": "CREDIT",
//       "round_off": "NO",
//       "payment_type": "CREDIT",
//       "date": "03/09/2024",
//       "due_date": "03/09/2024",
//       "bill_date": "03/09/2024",
//       "customform": "127",
//       "tax_type": "GST",
//       "transaction_type": "vendorcredit",
//       "account_segment": "1",
//       "sku": "NMR Off-Invoice -PHARMA",
//       "transactionsource": "EG_POS",
//       "department": 30,
//       "indian_tax_section_code": "294",
//       "description": "7% commission on xyz amount",
//       "total_amount": 6599.55,
//       "total_tax": 1187.919,
//       "total_CGST": 0.0,
//       "total_SGST": 0.0,
//       "total_IGST": 1187.919,
//       "total_cess": 0
//     }
//   ]
// }


import axios from 'axios';

export const fetchManageDocsData = async () => {
  try {
    const response = await axios.get(`http://nmrserver.wellnessforever.co.in/api/nmr/json_data`);
    const jsonData = response.data.data.map(doc => doc.json_data); 
    const docIds = response.data.data.map(doc => doc.doc_id); 
    return { jsonData, docIds } ;
  } catch (error) {
    console.error('Error fetching manageDocs data:', error);
    throw error;
  }
};


