import axios from 'axios';
import { FaLayerGroup, FaUsers, FaSync, FaBox, FaRupeeSign } from 'react-icons/fa';
import moment from 'moment';

const fetchManageDocsData = async () => {
  try {
    const response = await axios.get('http://nmrserver.wellnessforever.co.in/api/nmr/json_data');
    const jsonData = response.data.data.map(doc => doc.json_data);
    return jsonData;
  } catch (error) {
    console.error('Error fetching manageDocs data:', error);
    throw error;
  }
};

const calculateDataSync = (dDate) => {
  const firstOfMonth = moment(dDate).startOf('month');
  const differenceInDays = moment(dDate).diff(firstOfMonth, 'days') + 1;
  return `${differenceInDays} Days`;
};

const formatLargeNumber = (num) => {
  if (num >= 1e7) {
    return (num / 1e7).toFixed(2) + ' Cr'; 
  } else if (num >= 1e5) {
    return (num / 1e5).toFixed(2) + ' Lakh';
  }
  return num.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR'
  });
};

const calculateCardData = async () => {
  try {
    const manageDocsData = await fetchManageDocsData();

    const docsArray = Array.isArray(manageDocsData) ? manageDocsData : [manageDocsData];

    // const totalTOT = docsArray.reduce((sum, doc) => sum + doc.length, 0);
    const totalTOT = docsArray.length;

    const totalMFR = new Set(docsArray.map(doc => doc.mfac_name)).size;

    // const totalSKU = docsArray.reduce((sum, doc) => sum + (doc.details?.length || 0), 0);
    const totalSKU = docsArray.reduce((sum, doc) => sum + doc.trans_item_code_count, 0);

    const totalAmount = docsArray.reduce(
      (sum, doc) => sum + doc.details.reduce((detailSum, item) => detailSum + item.total_amount, 0),
      0
    );

    const dataSync = calculateDataSync(docsArray[0]?.d_date);
    const formattedTotalAmount = formatLargeNumber(totalAmount);

    return [
      { label: 'TOTAL TOT', value: `${totalTOT}`, icon: FaLayerGroup, iconBg: '#D14343' },
      { label: 'TOTAL MANUFACTURERS', value: `${totalMFR}`, icon: FaUsers, iconBg: '#14B8A6' },
      { label: 'DATA SYNC', value: dataSync, icon: FaSync, iconBg: '#FFB020' },
      { label: 'TOTAL SKU', value: `${totalSKU}`, icon: FaBox, iconBg: 'cyan' },
      { label: 'TOTAL AMOUNT', value: `${formattedTotalAmount}`, icon: FaRupeeSign, iconBg: '#5048E5' },
      // {
      //   label: 'TOTAL AMOUNT', value: `${totalAmount.toLocaleString('en-IN', {
      //     maximumFractionDigits: 2,
      //     style: 'currency',
      //     currency: 'INR'
      //   })}`, icon: FaDollarSign, iconBg: '#5048E5'
      // },
    ];
  } catch (error) {
    console.error('Error calculating card values:', error);
    return [];
  }
};

export default calculateCardData;