import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import Dashboard from './views/admin/dashboard';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const initialTheme = extendTheme({});

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null to start
  const navigate = useNavigate(); // For manual redirection

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      console.log('token', token);
      // Simulate token validation or set authenticated state based on token
      setIsAuthenticated(true); // or make an async call to validate the token
      navigate('/admin/dashboard');
    } else {
      setIsAuthenticated(false);
      navigate('/auth/sign-in'); // Redirect to login if no token
    }
  }, [navigate]);

  if (isAuthenticated === null) {
    // Optional: Display a loading spinner while checking authentication status
    return <div>Loading...</div>;
  }

  return (
    <ChakraProvider theme={currentTheme}>
      <Global
        styles={{
          body: {
            backgroundColor: '#F2F0F9',
          },
        }}
      />
      <Routes>
        {/* Authentication routes */}
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="/" element={<Navigate to="/auth/sign-in" />} />

        {/* Protected routes */}
        <Route
          path="admin/dashboard"
          element={
            isAuthenticated ? <Dashboard /> : <Navigate to="/auth/sign-in" />
          }
        />
        <Route
          path="admin/*"
          element={
            isAuthenticated ? (
              <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
            ) : (
              <Navigate to="/auth/sign-in" />
            )
          }
        />
        <Route
          path="rtl/*"
          element={
            isAuthenticated ? (
              <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
            ) : (
              <Navigate to="/auth/sign-in" />
            )
          }
        />
        {/* Default route for unmatched paths */}
        <Route path="*" element={<Navigate to="/auth/sign-in" />} />
      </Routes>
    </ChakraProvider>
  );
}
