// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-grid {
  border: none;
  border-radius: 20px;
  overflow: hidden;
}

.custom-grid .ag-root-wrapper {
  border: none;
  border-radius: 20px;
}

.custom-grid .ag-theme-alpine {
  border: none;
  border-radius: 20px;
}

.custom-grid .ag-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  font-size: 12px !important;
  font-weight: normal !important;
}

.custom-grid .ag-body-viewport,
.custom-grid .ag-paging-panel {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.custom-grid .ag-header-cell-sortable .ag-header-cell-label {
  font-size: 10px;
}

/* .custom-grid .ag-header-row {
  background-color: #F2F0F9;
} */

.ag-cell {
  font-size: 11px;
  font-weight: 500;
}

.chakra-tabs__tab-panel {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

`, "",{"version":3,"sources":["webpack://./src/assets/css/DataTable.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,6BAA6B;EAC7B,0BAA0B;EAC1B,8BAA8B;AAChC;;AAEA;;EAEE,+BAA+B;EAC/B,gCAAgC;AAClC;;AAEA;EACE,eAAe;AACjB;;AAEA;;GAEG;;AAEH;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,0BAA0B;AAC5B","sourcesContent":[".custom-grid {\r\n  border: none;\r\n  border-radius: 20px;\r\n  overflow: hidden;\r\n}\r\n\r\n.custom-grid .ag-root-wrapper {\r\n  border: none;\r\n  border-radius: 20px;\r\n}\r\n\r\n.custom-grid .ag-theme-alpine {\r\n  border: none;\r\n  border-radius: 20px;\r\n}\r\n\r\n.custom-grid .ag-header {\r\n  border-top-left-radius: 20px;\r\n  border-top-right-radius: 20px;\r\n  font-size: 12px !important;\r\n  font-weight: normal !important;\r\n}\r\n\r\n.custom-grid .ag-body-viewport,\r\n.custom-grid .ag-paging-panel {\r\n  border-bottom-left-radius: 20px;\r\n  border-bottom-right-radius: 20px;\r\n}\r\n\r\n.custom-grid .ag-header-cell-sortable .ag-header-cell-label {\r\n  font-size: 10px;\r\n}\r\n\r\n/* .custom-grid .ag-header-row {\r\n  background-color: #F2F0F9;\r\n} */\r\n\r\n.ag-cell {\r\n  font-size: 11px;\r\n  font-weight: 500;\r\n}\r\n\r\n.chakra-tabs__tab-panel {\r\n  padding-right: 0 !important;\r\n  padding-left: 0 !important;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
